import { prettify } from "@/util/stringUtil";

const defaultHeaderOptions = {
    includeId: false,
    align: "start",
    sortable: true
}

const generateHeaders = (data, options = {}) => {
    const opts = { ...defaultHeaderOptions, ...options };
    const keys = Reflect.ownKeys({ ...data[0] });

    return keys
        .filter((key) => opts.includeId || key !== "id")
        .map((key) => ({
            text: prettify(key),
            value: key,
            align: opts.align,
            sortable: opts.sortable
        }));
}

export {
    generateHeaders
}